/**
 * File generated by js-routes 2.2.4
 * Based on Rails 7.0.8 routes of Snaque::Application
 */
const __jsr = (() => {
    const hasProp = (value, key) => Object.prototype.hasOwnProperty.call(value, key);
    let NodeTypes;
    (function (NodeTypes) {
        NodeTypes[NodeTypes["GROUP"] = 1] = "GROUP";
        NodeTypes[NodeTypes["CAT"] = 2] = "CAT";
        NodeTypes[NodeTypes["SYMBOL"] = 3] = "SYMBOL";
        NodeTypes[NodeTypes["OR"] = 4] = "OR";
        NodeTypes[NodeTypes["STAR"] = 5] = "STAR";
        NodeTypes[NodeTypes["LITERAL"] = 6] = "LITERAL";
        NodeTypes[NodeTypes["SLASH"] = 7] = "SLASH";
        NodeTypes[NodeTypes["DOT"] = 8] = "DOT";
    })(NodeTypes || (NodeTypes = {}));
    const isBrowser = typeof window !== "undefined";
    const ModuleReferences = {
        CJS: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                module.exports = routes;
            },
            isSupported() {
                return typeof module === "object";
            },
        },
        AMD: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                define([], function () {
                    return routes;
                });
            },
            isSupported() {
                return typeof define === "function" && !!define.amd;
            },
        },
        UMD: {
            define(routes) {
                if (ModuleReferences.AMD.isSupported()) {
                    ModuleReferences.AMD.define(routes);
                }
                else {
                    if (ModuleReferences.CJS.isSupported()) {
                        try {
                            ModuleReferences.CJS.define(routes);
                        }
                        catch (error) {
                            if (error.name !== "TypeError")
                                throw error;
                        }
                    }
                }
            },
            isSupported() {
                return (ModuleReferences.AMD.isSupported() ||
                    ModuleReferences.CJS.isSupported());
            },
        },
        ESM: {
            define() {
                // Module can only be defined using ruby code generation
            },
            isSupported() {
                // Its impossible to check if "export" keyword is supported
                return true;
            },
        },
        NIL: {
            define() {
                // Defined using const __jsr = 
            },
            isSupported() {
                return true;
            },
        },
        DTS: {
            // Acts the same as ESM
            define(routes) {
                ModuleReferences.ESM.define(routes);
            },
            isSupported() {
                return ModuleReferences.ESM.isSupported();
            },
        },
    };
    class ParametersMissing extends Error {
        constructor(...keys) {
            super(`Route missing required keys: ${keys.join(", ")}`);
            this.keys = keys;
            Object.setPrototypeOf(this, Object.getPrototypeOf(this));
            this.name = ParametersMissing.name;
        }
    }
    const UriEncoderSegmentRegex = /[^a-zA-Z0-9\-._~!$&'()*+,;=:@]/g;
    const ReservedOptions = [
        "anchor",
        "trailing_slash",
        "subdomain",
        "host",
        "port",
        "protocol",
    ];
    class UtilsClass {
        constructor() {
            this.configuration = {
                prefix: "",
                default_url_options: {},
                special_options_key: "_options",
                serializer: null || this.default_serializer.bind(this),
            };
        }
        default_serializer(value, prefix) {
            if (this.is_nullable(value)) {
                return "";
            }
            if (!prefix && !this.is_object(value)) {
                throw new Error("Url parameters should be a javascript hash");
            }
            prefix = prefix || "";
            const result = [];
            if (this.is_array(value)) {
                for (const element of value) {
                    result.push(this.default_serializer(element, prefix + "[]"));
                }
            }
            else if (this.is_object(value)) {
                for (let key in value) {
                    if (!hasProp(value, key))
                        continue;
                    let prop = value[key];
                    if (this.is_nullable(prop) && prefix) {
                        prop = "";
                    }
                    if (this.is_not_nullable(prop)) {
                        if (prefix) {
                            key = prefix + "[" + key + "]";
                        }
                        result.push(this.default_serializer(prop, key));
                    }
                }
            }
            else {
                if (this.is_not_nullable(value)) {
                    result.push(encodeURIComponent(prefix) + "=" + encodeURIComponent("" + value));
                }
            }
            return result.join("&");
        }
        serialize(object) {
            return this.configuration.serializer(object);
        }
        extract_options(number_of_params, args) {
            const last_el = args[args.length - 1];
            if ((args.length > number_of_params && last_el === 0) ||
                (this.is_object(last_el) &&
                    !this.looks_like_serialized_model(last_el))) {
                if (this.is_object(last_el)) {
                    delete last_el[this.configuration.special_options_key];
                }
                return {
                    args: args.slice(0, args.length - 1),
                    options: last_el,
                };
            }
            else {
                return { args, options: {} };
            }
        }
        looks_like_serialized_model(object) {
            return (this.is_object(object) &&
                !(this.configuration.special_options_key in object) &&
                ("id" in object || "to_param" in object || "toParam" in object));
        }
        path_identifier(object) {
            const result = this.unwrap_path_identifier(object);
            return this.is_nullable(result) || result === false ? "" : "" + result;
        }
        unwrap_path_identifier(object) {
            let result = object;
            if (!this.is_object(object)) {
                return object;
            }
            if ("to_param" in object) {
                result = object.to_param;
            }
            else if ("toParam" in object) {
                result = object.toParam;
            }
            else if ("id" in object) {
                result = object.id;
            }
            else {
                result = object;
            }
            return this.is_callable(result) ? result.call(object) : result;
        }
        partition_parameters(parts, required_params, default_options, call_arguments) {
            // eslint-disable-next-line prefer-const
            let { args, options } = this.extract_options(parts.length, call_arguments);
            if (args.length > parts.length) {
                throw new Error("Too many parameters provided for path");
            }
            let use_all_parts = args.length > required_params.length;
            const parts_options = {
                ...this.configuration.default_url_options,
            };
            for (const key in options) {
                const value = options[key];
                if (!hasProp(options, key))
                    continue;
                use_all_parts = true;
                if (parts.includes(key)) {
                    parts_options[key] = value;
                }
            }
            options = {
                ...this.configuration.default_url_options,
                ...default_options,
                ...options,
            };
            const keyword_parameters = {};
            let query_parameters = {};
            for (const key in options) {
                if (!hasProp(options, key))
                    continue;
                const value = options[key];
                if (key === "params") {
                    if (this.is_object(value)) {
                        query_parameters = {
                            ...query_parameters,
                            ...value,
                        };
                    }
                    else {
                        throw new Error("params value should always be an object");
                    }
                }
                else if (this.is_reserved_option(key)) {
                    keyword_parameters[key] = value;
                }
                else {
                    if (!this.is_nullable(value) &&
                        (value !== default_options[key] || required_params.includes(key))) {
                        query_parameters[key] = value;
                    }
                }
            }
            const route_parts = use_all_parts ? parts : required_params;
            let i = 0;
            for (const part of route_parts) {
                if (i < args.length) {
                    const value = args[i];
                    if (!hasProp(parts_options, part)) {
                        query_parameters[part] = value;
                        ++i;
                    }
                }
            }
            return { keyword_parameters, query_parameters };
        }
        build_route(parts, required_params, default_options, route, absolute, args) {
            const { keyword_parameters, query_parameters, } = this.partition_parameters(parts, required_params, default_options, args);
            const missing_params = required_params.filter((param) => !hasProp(query_parameters, param) ||
                this.is_nullable(query_parameters[param]));
            if (missing_params.length) {
                throw new ParametersMissing(...missing_params);
            }
            let result = this.get_prefix() + this.visit(route, query_parameters);
            if (keyword_parameters.trailing_slash) {
                result = result.replace(/(.*?)[/]?$/, "$1/");
            }
            const url_params = this.serialize(query_parameters);
            if (url_params.length) {
                result += "?" + url_params;
            }
            result += keyword_parameters.anchor
                ? "#" + keyword_parameters.anchor
                : "";
            if (absolute) {
                result = this.route_url(keyword_parameters) + result;
            }
            return result;
        }
        visit(route, parameters, optional = false) {
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return this.visit(route[1], parameters, true);
                case NodeTypes.CAT:
                    return this.visit_cat(route, parameters, optional);
                case NodeTypes.SYMBOL:
                    return this.visit_symbol(route, parameters, optional);
                case NodeTypes.STAR:
                    return this.visit_globbing(route[1], parameters, true);
                case NodeTypes.LITERAL:
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        is_not_nullable(object) {
            return !this.is_nullable(object);
        }
        is_nullable(object) {
            return object === undefined || object === null;
        }
        visit_cat(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, left, right], parameters, optional) {
            const left_part = this.visit(left, parameters, optional);
            let right_part = this.visit(right, parameters, optional);
            if (optional &&
                ((this.is_optional_node(left[0]) && !left_part) ||
                    (this.is_optional_node(right[0]) && !right_part))) {
                return "";
            }
            // if left_part ends on '/' and right_part starts on '/'
            if (left_part[left_part.length - 1] === "/" && right_part[0] === "/") {
                // strip slash from right_part
                // to prevent double slash
                right_part = right_part.substring(1);
            }
            return left_part + right_part;
        }
        visit_symbol(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, key], parameters, optional) {
            const value = this.path_identifier(parameters[key]);
            delete parameters[key];
            if (value.length) {
                return this.encode_segment(value);
            }
            if (optional) {
                return "";
            }
            else {
                throw new ParametersMissing(key);
            }
        }
        encode_segment(segment) {
            return segment.replace(UriEncoderSegmentRegex, (str) => encodeURIComponent(str));
        }
        is_optional_node(node) {
            return [NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT].includes(node);
        }
        build_path_spec(route, wildcard = false) {
            let key;
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return "(" + this.build_path_spec(route[1]) + ")";
                case NodeTypes.CAT:
                    return (this.build_path_spec(route[1]) + this.build_path_spec(route[2]));
                case NodeTypes.STAR:
                    return this.build_path_spec(route[1], true);
                case NodeTypes.SYMBOL:
                    key = route[1];
                    if (wildcard) {
                        return (key.startsWith("*") ? "" : "*") + key;
                    }
                    else {
                        return ":" + key;
                    }
                    break;
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                case NodeTypes.LITERAL:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        visit_globbing(route, parameters, optional) {
            const key = route[1];
            let value = parameters[key];
            delete parameters[key];
            if (this.is_nullable(value)) {
                return this.visit(route, parameters, optional);
            }
            if (this.is_array(value)) {
                value = value.join("/");
            }
            const result = this.path_identifier(value);
            return false
                ? result
                : encodeURI(result);
        }
        get_prefix() {
            const prefix = this.configuration.prefix;
            return prefix.match("/$")
                ? prefix.substring(0, prefix.length - 1)
                : prefix;
        }
        route(parts_table, route_spec, absolute = false) {
            const required_params = [];
            const parts = [];
            const default_options = {};
            for (const [part, { r: required, d: value }] of Object.entries(parts_table)) {
                parts.push(part);
                if (required) {
                    required_params.push(part);
                }
                if (this.is_not_nullable(value)) {
                    default_options[part] = value;
                }
            }
            const result = (...args) => {
                return this.build_route(parts, required_params, default_options, route_spec, absolute, args);
            };
            result.requiredParams = () => required_params;
            result.toString = () => {
                return this.build_path_spec(route_spec);
            };
            return result;
        }
        route_url(route_defaults) {
            const hostname = route_defaults.host || this.current_host();
            if (!hostname) {
                return "";
            }
            const subdomain = route_defaults.subdomain
                ? route_defaults.subdomain + "."
                : "";
            const protocol = route_defaults.protocol || this.current_protocol();
            let port = route_defaults.port ||
                (!route_defaults.host ? this.current_port() : undefined);
            port = port ? ":" + port : "";
            return protocol + "://" + subdomain + hostname + port;
        }
        current_host() {
            var _a;
            return (isBrowser && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.hostname)) || "";
        }
        current_protocol() {
            var _a, _b;
            return ((isBrowser && ((_b = (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.protocol) === null || _b === void 0 ? void 0 : _b.replace(/:$/, ""))) || "http");
        }
        current_port() {
            var _a;
            return (isBrowser && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.port)) || "";
        }
        is_object(value) {
            return (typeof value === "object" &&
                Object.prototype.toString.call(value) === "[object Object]");
        }
        is_array(object) {
            return object instanceof Array;
        }
        is_callable(object) {
            return typeof object === "function" && !!object.call;
        }
        is_reserved_option(key) {
            return ReservedOptions.includes(key);
        }
        configure(new_config) {
            this.configuration = { ...this.configuration, ...new_config };
            return this.configuration;
        }
        config() {
            return { ...this.configuration };
        }
        is_module_supported(name) {
            return ModuleReferences[name].isSupported();
        }
        ensure_module_supported(name) {
            if (!this.is_module_supported(name)) {
                throw new Error(`${name} is not supported by runtime`);
            }
        }
        define_module(name, module) {
            this.ensure_module_supported(name);
            ModuleReferences[name].define(module);
        }
    }
    const Utils = new UtilsClass();
    // We want this helper name to be short
    const __jsr = {
        r(parts_table, route_spec, absolute) {
            return Utils.route(parts_table, route_spec, absolute);
        },
    };
    const result = {
        ...__jsr,
        configure: (config) => {
            return Utils.configure(config);
        },
        config: () => {
            return Utils.config();
        },
        serialize: (object) => {
            return Utils.serialize(object);
        },
        ...{},
    };
    Utils.define_module("ESM", result);
    return result;
})();
export const configure = __jsr.configure;

export const config = __jsr.config;

export const serialize = __jsr.serialize;

/**
 * Generates rails route to
 * /backoffice/users/:user_id/received_resource_invitations/:id/accept(.:format)
 * @param {any} user_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const accept_admin_user_received_resource_invitation_path = __jsr.r({"user_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"user_id"],[2,[7,"/"],[2,[6,"received_resource_invitations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"accept"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /accept_offer(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const accept_offer_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"accept_offer"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /shared_campaign_previews/accept_offer(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const accept_offer_shared_campaign_previews_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"shared_campaign_previews"],[2,[7,"/"],[2,[6,"accept_offer"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/authentications/events(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_authentications_events_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"authentications"],[2,[7,"/"],[2,[6,"events"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/bites/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_bite_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"bites"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/bite_nodes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_bite_node_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"bite_nodes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/campaigns/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_campaign_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"campaigns"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/campaigns/:campaign_id/budget_periods(.:format)
 * @param {any} campaign_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_campaign_budget_periods_path = __jsr.r({"campaign_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"campaigns"],[2,[7,"/"],[2,[3,"campaign_id"],[2,[7,"/"],[2,[6,"budget_periods"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/campaigns/:campaign_id/campaign_preview(.:format)
 * @param {any} campaign_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_campaign_campaign_preview_path = __jsr.r({"campaign_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"campaigns"],[2,[7,"/"],[2,[3,"campaign_id"],[2,[7,"/"],[2,[6,"campaign_preview"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/campaigns/:campaign_id/daily_expenses(.:format)
 * @param {any} campaign_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_campaign_daily_expenses_path = __jsr.r({"campaign_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"campaigns"],[2,[7,"/"],[2,[3,"campaign_id"],[2,[7,"/"],[2,[6,"daily_expenses"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/campaigns/:campaign_id/snaquebars(.:format)
 * @param {any} campaign_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_campaign_snaquebars_path = __jsr.r({"campaign_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"campaigns"],[2,[7,"/"],[2,[3,"campaign_id"],[2,[7,"/"],[2,[6,"snaquebars"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/campaign_suggestions/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_campaign_suggestion_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"campaign_suggestions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/campaigns/:campaign_id/views/traversals(.:format)
 * @param {any} campaign_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_campaign_views_traversals_path = __jsr.r({"campaign_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"campaigns"],[2,[7,"/"],[2,[3,"campaign_id"],[2,[7,"/"],[2,[6,"views"],[2,[7,"/"],[2,[6,"traversals"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/campaigns(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_campaigns_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"campaigns"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/clients/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_client_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/content_provider_werbetreibende/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_client_advertiser_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"content_provider_werbetreibende"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/content_provider_werbetreibende/:client_advertiser_id/campaign_suggestions(.:format)
 * @param {any} client_advertiser_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_client_advertiser_campaign_suggestions_path = __jsr.r({"client_advertiser_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"content_provider_werbetreibende"],[2,[7,"/"],[2,[3,"client_advertiser_id"],[2,[7,"/"],[2,[6,"campaign_suggestions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/content_provider_werbetreibende/:client_advertiser_id/campaigns(.:format)
 * @param {any} client_advertiser_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_client_advertiser_campaigns_path = __jsr.r({"client_advertiser_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"content_provider_werbetreibende"],[2,[7,"/"],[2,[3,"client_advertiser_id"],[2,[7,"/"],[2,[6,"campaigns"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/clients/:client_id/client_advertisers(.:format)
 * @param {any} client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_client_client_advertisers_path = __jsr.r({"client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"client_id"],[2,[7,"/"],[2,[6,"client_advertisers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/clients/:client_id/projects(.:format)
 * @param {any} client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_client_projects_path = __jsr.r({"client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"client_id"],[2,[7,"/"],[2,[6,"projects"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/clients/:client_id/resource_invitations/:id(.:format)
 * @param {any} client_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_client_resource_invitation_path = __jsr.r({"client_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"client_id"],[2,[7,"/"],[2,[6,"resource_invitations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/clients/:client_id/resource_invitations(.:format)
 * @param {any} client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_client_resource_invitations_path = __jsr.r({"client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"client_id"],[2,[7,"/"],[2,[6,"resource_invitations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/clients/:client_id/user_resources/:id(.:format)
 * @param {any} client_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_client_user_resource_path = __jsr.r({"client_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"client_id"],[2,[7,"/"],[2,[6,"user_resources"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/clients/:client_id/user_resources(.:format)
 * @param {any} client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_client_user_resources_path = __jsr.r({"client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"client_id"],[2,[7,"/"],[2,[6,"user_resources"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/clients(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_clients_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"clients"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/globale_werbetreibende/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_global_advertiser_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"globale_werbetreibende"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/globale_werbetreibende/:global_advertiser_id/campaign_suggestions(.:format)
 * @param {any} global_advertiser_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_global_advertiser_campaign_suggestions_path = __jsr.r({"global_advertiser_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"globale_werbetreibende"],[2,[7,"/"],[2,[3,"global_advertiser_id"],[2,[7,"/"],[2,[6,"campaign_suggestions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/globale_werbetreibende/:global_advertiser_id/campaigns(.:format)
 * @param {any} global_advertiser_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_global_advertiser_campaigns_path = __jsr.r({"global_advertiser_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"globale_werbetreibende"],[2,[7,"/"],[2,[3,"global_advertiser_id"],[2,[7,"/"],[2,[6,"campaigns"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/globale_werbetreibende/:global_advertiser_id/resource_invitations/:id(.:format)
 * @param {any} global_advertiser_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_global_advertiser_resource_invitation_path = __jsr.r({"global_advertiser_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"globale_werbetreibende"],[2,[7,"/"],[2,[3,"global_advertiser_id"],[2,[7,"/"],[2,[6,"resource_invitations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/globale_werbetreibende/:global_advertiser_id/resource_invitations(.:format)
 * @param {any} global_advertiser_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_global_advertiser_resource_invitations_path = __jsr.r({"global_advertiser_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"globale_werbetreibende"],[2,[7,"/"],[2,[3,"global_advertiser_id"],[2,[7,"/"],[2,[6,"resource_invitations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/globale_werbetreibende/:global_advertiser_id/user_resources/:id(.:format)
 * @param {any} global_advertiser_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_global_advertiser_user_resource_path = __jsr.r({"global_advertiser_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"globale_werbetreibende"],[2,[7,"/"],[2,[3,"global_advertiser_id"],[2,[7,"/"],[2,[6,"user_resources"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/globale_werbetreibende/:global_advertiser_id/user_resources(.:format)
 * @param {any} global_advertiser_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_global_advertiser_user_resources_path = __jsr.r({"global_advertiser_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"globale_werbetreibende"],[2,[7,"/"],[2,[3,"global_advertiser_id"],[2,[7,"/"],[2,[6,"user_resources"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/globale_werbetreibende(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_global_advertisers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"globale_werbetreibende"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/global_snaque_fees(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_global_snaque_fees_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"global_snaque_fees"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/global_stats/dashboard(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_global_stats_dashboard_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"global_stats"],[2,[7,"/"],[2,[6,"dashboard"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/global_stats/impressions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_global_stats_impressions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"global_stats"],[2,[7,"/"],[2,[6,"impressions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/global_stats/snaqueings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_global_stats_snaqueings_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"global_stats"],[2,[7,"/"],[2,[6,"snaqueings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/media_items/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_media_item_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"media_items"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/projects/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_project_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/projects/:project_id/campaign_suggestions(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_project_campaign_suggestions_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"campaign_suggestions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/projects/:project_id/campaigns(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_project_campaigns_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"campaigns"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/projects/:project_id/daily_fees(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_project_daily_fees_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"daily_fees"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/projects/:project_id/daily_profits(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_project_daily_profits_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"daily_profits"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/projects/:project_id/daily_revenues(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_project_daily_revenues_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"daily_revenues"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/projects/:project_id/engagement_costs(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_project_engagement_costs_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"engagement_costs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/projects/:project_id/intro_bite(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_project_intro_bite_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"intro_bite"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/projects/:project_id/kiosk_configuration(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_project_kiosk_configuration_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"kiosk_configuration"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/projects/:project_id/project_advertiser_stats(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_project_project_advertiser_stats_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"project_advertiser_stats"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/projects/:project_id/snaque_fees(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_project_snaque_fees_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"snaque_fees"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/projects/:project_id/views/traversals(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_project_views_traversals_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"views"],[2,[7,"/"],[2,[6,"traversals"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_root_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /backoffice/sidekiq
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_sidekiq_web_path = __jsr.r({}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[6,"sidekiq"]]]]);

/**
 * Generates rails route to
 * /backoffice/snaquebars/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_snaquebar_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"snaquebars"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/snaquebars/:snaquebar_id/bite_nodes/:id(.:format)
 * @param {any} snaquebar_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_snaquebar_bite_node_path = __jsr.r({"snaquebar_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"snaquebars"],[2,[7,"/"],[2,[3,"snaquebar_id"],[2,[7,"/"],[2,[6,"bite_nodes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/snaquebars/:snaquebar_id/bite_nodes(.:format)
 * @param {any} snaquebar_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_snaquebar_bite_nodes_path = __jsr.r({"snaquebar_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"snaquebars"],[2,[7,"/"],[2,[3,"snaquebar_id"],[2,[7,"/"],[2,[6,"bite_nodes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/snaquebars/:snaquebar_id/default_configurations/:id(.:format)
 * @param {any} snaquebar_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_snaquebar_default_configuration_path = __jsr.r({"snaquebar_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"snaquebars"],[2,[7,"/"],[2,[3,"snaquebar_id"],[2,[7,"/"],[2,[6,"default_configurations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/snaquebars/:snaquebar_id/default_configurations(.:format)
 * @param {any} snaquebar_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_snaquebar_default_configurations_path = __jsr.r({"snaquebar_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"snaquebars"],[2,[7,"/"],[2,[3,"snaquebar_id"],[2,[7,"/"],[2,[6,"default_configurations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/snaquebars/:snaquebar_id/media_items(.:format)
 * @param {any} snaquebar_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_snaquebar_media_items_path = __jsr.r({"snaquebar_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"snaquebars"],[2,[7,"/"],[2,[3,"snaquebar_id"],[2,[7,"/"],[2,[6,"media_items"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/snaquebars/:snaquebar_id/views/traversals(.:format)
 * @param {any} snaquebar_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_snaquebar_views_traversals_path = __jsr.r({"snaquebar_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"snaquebars"],[2,[7,"/"],[2,[3,"snaquebar_id"],[2,[7,"/"],[2,[6,"views"],[2,[7,"/"],[2,[6,"traversals"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/snaquebars(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_snaquebars_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"snaquebars"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/snaqueings/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_snaqueing_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"snaqueings"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/tags(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_tags_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"tags"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/users/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/users/:user_id/received_resource_invitations/:id(.:format)
 * @param {any} user_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_user_received_resource_invitation_path = __jsr.r({"user_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"user_id"],[2,[7,"/"],[2,[6,"received_resource_invitations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/users/:user_id/received_resource_invitations(.:format)
 * @param {any} user_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_user_received_resource_invitations_path = __jsr.r({"user_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"user_id"],[2,[7,"/"],[2,[6,"received_resource_invitations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/users/:user_id/user_resources(.:format)
 * @param {any} user_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_user_user_resources_path = __jsr.r({"user_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"user_id"],[2,[7,"/"],[2,[6,"user_resources"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/campaigns/:id/archive(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const archive_admin_campaign_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"campaigns"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"archive"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/snaquebars/:id/archive(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const archive_admin_snaquebar_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"snaquebars"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"archive"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/campaign_suggestions/:id/bite_image(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bite_image_admin_campaign_suggestion_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"campaign_suggestions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"bite_image"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/tags/categories(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const categories_admin_tags_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"tags"],[2,[7,"/"],[2,[6,"categories"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/campaigns/:id/charts(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const charts_admin_campaign_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"campaigns"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"charts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/projects/:id/charts(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const charts_admin_project_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"charts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/snaquebars/:id/copy(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const copy_admin_snaquebar_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"snaquebars"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"copy"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/campaigns/:campaign_id/snaqueing_action_aggregations/counts(.:format)
 * @param {any} campaign_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const counts_admin_campaign_snaqueing_action_aggregations_path = __jsr.r({"campaign_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"campaigns"],[2,[7,"/"],[2,[3,"campaign_id"],[2,[7,"/"],[2,[6,"snaqueing_action_aggregations"],[2,[7,"/"],[2,[6,"counts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/projects/:project_id/snaqueing_action_aggregations/counts(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const counts_admin_project_snaqueing_action_aggregations_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"snaqueing_action_aggregations"],[2,[7,"/"],[2,[6,"counts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/snaquebars/:snaquebar_id/snaqueing_action_aggregations/counts(.:format)
 * @param {any} snaquebar_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const counts_admin_snaquebar_snaqueing_action_aggregations_path = __jsr.r({"snaquebar_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"snaquebars"],[2,[7,"/"],[2,[3,"snaquebar_id"],[2,[7,"/"],[2,[6,"snaqueing_action_aggregations"],[2,[7,"/"],[2,[6,"counts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/snaquebars/:snaquebar_id/bites/:bite_type/create(.:format)
 * @param {any} snaquebar_id
 * @param {any} bite_type
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_bite_admin_snaquebar_bites_path = __jsr.r({"snaquebar_id":{"r":true},"bite_type":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"snaquebars"],[2,[7,"/"],[2,[3,"snaquebar_id"],[2,[7,"/"],[2,[6,"bites"],[2,[7,"/"],[2,[3,"bite_type"],[2,[7,"/"],[2,[6,"create"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/campaign_suggestions/:id/create_campaign(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_campaign_admin_campaign_suggestion_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"campaign_suggestions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"create_campaign"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/campaigns/:campaign_id/snaqueings/data_button_impressions(.:format)
 * @param {any} campaign_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const data_button_impressions_admin_campaign_snaqueings_path = __jsr.r({"campaign_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"campaigns"],[2,[7,"/"],[2,[3,"campaign_id"],[2,[7,"/"],[2,[6,"snaqueings"],[2,[7,"/"],[2,[6,"data_button_impressions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/projects/:project_id/snaqueings/data_button_impressions(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const data_button_impressions_admin_project_snaqueings_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"snaqueings"],[2,[7,"/"],[2,[6,"data_button_impressions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/snaquebars/:snaquebar_id/snaqueings/data_button_impressions(.:format)
 * @param {any} snaquebar_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const data_button_impressions_admin_snaquebar_snaqueings_path = __jsr.r({"snaquebar_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"snaquebars"],[2,[7,"/"],[2,[3,"snaquebar_id"],[2,[7,"/"],[2,[6,"snaqueings"],[2,[7,"/"],[2,[6,"data_button_impressions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/campaigns/:campaign_id/snaqueings/data_impression_success_rate_by_variant(.:format)
 * @param {any} campaign_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const data_impression_success_rate_by_variant_admin_campaign_snaqueings_path = __jsr.r({"campaign_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"campaigns"],[2,[7,"/"],[2,[3,"campaign_id"],[2,[7,"/"],[2,[6,"snaqueings"],[2,[7,"/"],[2,[6,"data_impression_success_rate_by_variant"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/projects/:project_id/snaqueings/data_impression_success_rate_by_variant(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const data_impression_success_rate_by_variant_admin_project_snaqueings_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"snaqueings"],[2,[7,"/"],[2,[6,"data_impression_success_rate_by_variant"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/snaquebars/:snaquebar_id/snaqueings/data_impression_success_rate_by_variant(.:format)
 * @param {any} snaquebar_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const data_impression_success_rate_by_variant_admin_snaquebar_snaqueings_path = __jsr.r({"snaquebar_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"snaquebars"],[2,[7,"/"],[2,[3,"snaquebar_id"],[2,[7,"/"],[2,[6,"snaqueings"],[2,[7,"/"],[2,[6,"data_impression_success_rate_by_variant"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/campaigns/:campaign_id/snaqueings/data_snaqueing_duration(.:format)
 * @param {any} campaign_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const data_snaqueing_duration_admin_campaign_snaqueings_path = __jsr.r({"campaign_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"campaigns"],[2,[7,"/"],[2,[3,"campaign_id"],[2,[7,"/"],[2,[6,"snaqueings"],[2,[7,"/"],[2,[6,"data_snaqueing_duration"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/projects/:project_id/snaqueings/data_snaqueing_duration(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const data_snaqueing_duration_admin_project_snaqueings_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"snaqueings"],[2,[7,"/"],[2,[6,"data_snaqueing_duration"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/snaquebars/:snaquebar_id/snaqueings/data_snaqueing_duration(.:format)
 * @param {any} snaquebar_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const data_snaqueing_duration_admin_snaquebar_snaqueings_path = __jsr.r({"snaquebar_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"snaquebars"],[2,[7,"/"],[2,[3,"snaquebar_id"],[2,[7,"/"],[2,[6,"snaqueings"],[2,[7,"/"],[2,[6,"data_snaqueing_duration"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/campaigns/:campaign_id/snaqueings/data_success_rates(.:format)
 * @param {any} campaign_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const data_success_rates_admin_campaign_snaqueings_path = __jsr.r({"campaign_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"campaigns"],[2,[7,"/"],[2,[3,"campaign_id"],[2,[7,"/"],[2,[6,"snaqueings"],[2,[7,"/"],[2,[6,"data_success_rates"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/projects/:project_id/snaqueings/data_success_rates(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const data_success_rates_admin_project_snaqueings_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"snaqueings"],[2,[7,"/"],[2,[6,"data_success_rates"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/snaquebars/:snaquebar_id/snaqueings/data_success_rates(.:format)
 * @param {any} snaquebar_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const data_success_rates_admin_snaquebar_snaqueings_path = __jsr.r({"snaquebar_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"snaquebars"],[2,[7,"/"],[2,[3,"snaquebar_id"],[2,[7,"/"],[2,[6,"snaqueings"],[2,[7,"/"],[2,[6,"data_success_rates"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/campaigns/:campaign_id/snaqueings/data_top_resources(.:format)
 * @param {any} campaign_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const data_top_resources_admin_campaign_snaqueings_path = __jsr.r({"campaign_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"campaigns"],[2,[7,"/"],[2,[3,"campaign_id"],[2,[7,"/"],[2,[6,"snaqueings"],[2,[7,"/"],[2,[6,"data_top_resources"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/projects/:project_id/snaqueings/data_top_resources(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const data_top_resources_admin_project_snaqueings_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"snaqueings"],[2,[7,"/"],[2,[6,"data_top_resources"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/snaquebars/:snaquebar_id/snaqueings/data_top_resources(.:format)
 * @param {any} snaquebar_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const data_top_resources_admin_snaquebar_snaqueings_path = __jsr.r({"snaquebar_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"snaquebars"],[2,[7,"/"],[2,[3,"snaquebar_id"],[2,[7,"/"],[2,[6,"snaqueings"],[2,[7,"/"],[2,[6,"data_top_resources"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/campaigns/:campaign_id/snaqueings/data_total_snaqueings(.:format)
 * @param {any} campaign_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const data_total_snaqueings_admin_campaign_snaqueings_path = __jsr.r({"campaign_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"campaigns"],[2,[7,"/"],[2,[3,"campaign_id"],[2,[7,"/"],[2,[6,"snaqueings"],[2,[7,"/"],[2,[6,"data_total_snaqueings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/projects/:project_id/snaqueings/data_total_snaqueings(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const data_total_snaqueings_admin_project_snaqueings_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"snaqueings"],[2,[7,"/"],[2,[6,"data_total_snaqueings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/snaquebars/:snaquebar_id/snaqueings/data_total_snaqueings(.:format)
 * @param {any} snaquebar_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const data_total_snaqueings_admin_snaquebar_snaqueings_path = __jsr.r({"snaquebar_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"snaquebars"],[2,[7,"/"],[2,[3,"snaquebar_id"],[2,[7,"/"],[2,[6,"snaqueings"],[2,[7,"/"],[2,[6,"data_total_snaqueings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/campaigns/:campaign_id/snaqueings/data_traversals(.:format)
 * @param {any} campaign_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const data_traversals_admin_campaign_snaqueings_path = __jsr.r({"campaign_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"campaigns"],[2,[7,"/"],[2,[3,"campaign_id"],[2,[7,"/"],[2,[6,"snaqueings"],[2,[7,"/"],[2,[6,"data_traversals"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/projects/:project_id/snaqueings/data_traversals(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const data_traversals_admin_project_snaqueings_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"snaqueings"],[2,[7,"/"],[2,[6,"data_traversals"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/snaquebars/:snaquebar_id/snaqueings/data_traversals(.:format)
 * @param {any} snaquebar_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const data_traversals_admin_snaquebar_snaqueings_path = __jsr.r({"snaquebar_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"snaquebars"],[2,[7,"/"],[2,[3,"snaquebar_id"],[2,[7,"/"],[2,[6,"snaqueings"],[2,[7,"/"],[2,[6,"data_traversals"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/campaigns/:campaign_id/snaqueings/data_variants(.:format)
 * @param {any} campaign_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const data_variants_admin_campaign_snaqueings_path = __jsr.r({"campaign_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"campaigns"],[2,[7,"/"],[2,[3,"campaign_id"],[2,[7,"/"],[2,[6,"snaqueings"],[2,[7,"/"],[2,[6,"data_variants"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/projects/:project_id/snaqueings/data_variants(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const data_variants_admin_project_snaqueings_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"snaqueings"],[2,[7,"/"],[2,[6,"data_variants"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/snaquebars/:snaquebar_id/snaqueings/data_variants(.:format)
 * @param {any} snaquebar_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const data_variants_admin_snaquebar_snaqueings_path = __jsr.r({"snaquebar_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"snaquebars"],[2,[7,"/"],[2,[3,"snaquebar_id"],[2,[7,"/"],[2,[6,"snaqueings"],[2,[7,"/"],[2,[6,"data_variants"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/campaign_suggestions/:id/delete_last_message(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const delete_last_message_admin_campaign_suggestion_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"campaign_suggestions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"delete_last_message"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/bites/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_bite_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"bites"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/bite_nodes/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_bite_node_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"bite_nodes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/campaigns/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_campaign_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"campaigns"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/clients/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_client_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/content_provider_werbetreibende/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_client_advertiser_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"content_provider_werbetreibende"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/clients/:client_id/user_resources/:id/edit(.:format)
 * @param {any} client_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_client_user_resource_path = __jsr.r({"client_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"client_id"],[2,[7,"/"],[2,[6,"user_resources"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/globale_werbetreibende/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_global_advertiser_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"globale_werbetreibende"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/globale_werbetreibende/:global_advertiser_id/user_resources/:id/edit(.:format)
 * @param {any} global_advertiser_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_global_advertiser_user_resource_path = __jsr.r({"global_advertiser_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"globale_werbetreibende"],[2,[7,"/"],[2,[3,"global_advertiser_id"],[2,[7,"/"],[2,[6,"user_resources"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/media_items/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_media_item_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"media_items"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/projects/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_project_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/projects/:project_id/intro_bite/edit(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_project_intro_bite_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"intro_bite"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/projects/:project_id/kiosk_configuration/edit(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_project_kiosk_configuration_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"kiosk_configuration"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/snaquebars/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_snaquebar_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"snaquebars"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/snaquebars/:snaquebar_id/bite_nodes/:id/edit(.:format)
 * @param {any} snaquebar_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_snaquebar_bite_node_path = __jsr.r({"snaquebar_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"snaquebars"],[2,[7,"/"],[2,[3,"snaquebar_id"],[2,[7,"/"],[2,[6,"bite_nodes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/snaquebars/:snaquebar_id/default_configurations/:id/edit(.:format)
 * @param {any} snaquebar_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_snaquebar_default_configuration_path = __jsr.r({"snaquebar_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"snaquebars"],[2,[7,"/"],[2,[3,"snaquebar_id"],[2,[7,"/"],[2,[6,"default_configurations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/users/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /identity/email/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_identity_email_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"identity"],[2,[7,"/"],[2,[6,"email"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /identity/email_verification/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_identity_email_verification_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"identity"],[2,[7,"/"],[2,[6,"email_verification"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /identity/password_reset/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_identity_password_reset_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"identity"],[2,[7,"/"],[2,[6,"password_reset"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /password/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_rails_conductor_inbound_email_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /snaquebars/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_snaquebar_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"snaquebars"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /two_factor_authentication/totp/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_two_factor_authentication_totp_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"two_factor_authentication"],[2,[7,"/"],[2,[6,"totp"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /identity/email(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const identity_email_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"identity"],[2,[7,"/"],[2,[6,"email"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /identity/email_verification(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const identity_email_verification_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"identity"],[2,[7,"/"],[2,[6,"email_verification"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /identity/password_reset(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const identity_password_reset_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"identity"],[2,[7,"/"],[2,[6,"password_reset"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /impressions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const impressions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"impressions"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /healthchecks/impressions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const impressions_healthchecks_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"healthchecks"],[2,[7,"/"],[2,[6,"impressions"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/snaquebars/:snaquebar_id/bites/:bite_type(.:format)
 * @param {any} snaquebar_id
 * @param {any} bite_type
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const list_admin_snaquebar_bites_path = __jsr.r({"snaquebar_id":{"r":true},"bite_type":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"snaquebars"],[2,[7,"/"],[2,[3,"snaquebar_id"],[2,[7,"/"],[2,[6,"bites"],[2,[7,"/"],[2,[3,"bite_type"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/snaquebars/:id/manage_content(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_content_admin_snaquebar_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"snaquebars"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"manage_content"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/users/my_account(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const my_account_admin_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"my_account"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/campaigns/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_campaign_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"campaigns"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/campaigns/:campaign_id/budget_periods/new(.:format)
 * @param {any} campaign_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_campaign_budget_period_path = __jsr.r({"campaign_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"campaigns"],[2,[7,"/"],[2,[3,"campaign_id"],[2,[7,"/"],[2,[6,"budget_periods"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/campaigns/:campaign_id/snaquebars/new(.:format)
 * @param {any} campaign_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_campaign_snaquebar_path = __jsr.r({"campaign_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"campaigns"],[2,[7,"/"],[2,[3,"campaign_id"],[2,[7,"/"],[2,[6,"snaquebars"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/clients/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_client_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/content_provider_werbetreibende/:client_advertiser_id/campaigns/new(.:format)
 * @param {any} client_advertiser_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_client_advertiser_campaign_path = __jsr.r({"client_advertiser_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"content_provider_werbetreibende"],[2,[7,"/"],[2,[3,"client_advertiser_id"],[2,[7,"/"],[2,[6,"campaigns"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/clients/:client_id/client_advertisers/new(.:format)
 * @param {any} client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_client_client_advertiser_path = __jsr.r({"client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"client_id"],[2,[7,"/"],[2,[6,"client_advertisers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/clients/:client_id/projects/new(.:format)
 * @param {any} client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_client_project_path = __jsr.r({"client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"client_id"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/clients/:client_id/resource_invitations/new(.:format)
 * @param {any} client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_client_resource_invitation_path = __jsr.r({"client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"client_id"],[2,[7,"/"],[2,[6,"resource_invitations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/globale_werbetreibende/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_global_advertiser_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"globale_werbetreibende"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/globale_werbetreibende/:global_advertiser_id/campaigns/new(.:format)
 * @param {any} global_advertiser_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_global_advertiser_campaign_path = __jsr.r({"global_advertiser_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"globale_werbetreibende"],[2,[7,"/"],[2,[3,"global_advertiser_id"],[2,[7,"/"],[2,[6,"campaigns"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/globale_werbetreibende/:global_advertiser_id/resource_invitations/new(.:format)
 * @param {any} global_advertiser_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_global_advertiser_resource_invitation_path = __jsr.r({"global_advertiser_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"globale_werbetreibende"],[2,[7,"/"],[2,[3,"global_advertiser_id"],[2,[7,"/"],[2,[6,"resource_invitations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/global_snaque_fees/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_global_snaque_fee_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"global_snaque_fees"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/projects/:project_id/campaigns/new(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_project_campaign_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"campaigns"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/projects/:project_id/engagement_costs/new(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_project_engagement_cost_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"engagement_costs"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/projects/:project_id/snaque_fees/new(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_project_snaque_fee_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"snaque_fees"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/snaquebars/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_snaquebar_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"snaquebars"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/snaquebars/:snaquebar_id/bite_nodes/new(.:format)
 * @param {any} snaquebar_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_snaquebar_bite_node_path = __jsr.r({"snaquebar_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"snaquebars"],[2,[7,"/"],[2,[3,"snaquebar_id"],[2,[7,"/"],[2,[6,"bite_nodes"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/snaquebars/:snaquebar_id/media_items/new(.:format)
 * @param {any} snaquebar_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_snaquebar_media_item_path = __jsr.r({"snaquebar_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"snaquebars"],[2,[7,"/"],[2,[3,"snaquebar_id"],[2,[7,"/"],[2,[6,"media_items"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/users/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_user_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/snaquebars/:snaquebar_id/bites/:bite_type/new(.:format)
 * @param {any} snaquebar_id
 * @param {any} bite_type
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_bite_admin_snaquebar_bites_path = __jsr.r({"snaquebar_id":{"r":true},"bite_type":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"snaquebars"],[2,[7,"/"],[2,[3,"snaquebar_id"],[2,[7,"/"],[2,[6,"bites"],[2,[7,"/"],[2,[3,"bite_type"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /identity/password_reset/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_identity_password_reset_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"identity"],[2,[7,"/"],[2,[6,"password_reset"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_rails_conductor_inbound_email_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/sources/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_rails_conductor_inbound_email_source_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"sources"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /sessions/sudo/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_sessions_sudo_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sessions"],[2,[7,"/"],[2,[6,"sudo"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /snaquebars/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_snaquebar_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"snaquebars"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /two_factor_authentication/challenge/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_two_factor_authentication_challenge_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"two_factor_authentication"],[2,[7,"/"],[2,[6,"challenge"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /two_factor_authentication/totp/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_two_factor_authentication_totp_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"two_factor_authentication"],[2,[7,"/"],[2,[6,"totp"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"password"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /backoffice/snaquebars/:id/paste(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const paste_admin_snaquebar_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"snaquebars"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"paste"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/campaigns/:id/pause(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pause_admin_campaign_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"campaigns"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"pause"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/snaquebars/:id/pause(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pause_admin_snaquebar_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"snaquebars"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"pause"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/projects/:project_id/engagement_costs/preview(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const preview_admin_project_engagement_costs_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"engagement_costs"],[2,[7,"/"],[2,[6,"preview"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/projects/:project_id/intro_bite/preview(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const preview_admin_project_intro_bite_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"intro_bite"],[2,[7,"/"],[2,[6,"preview"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/snaquebars/:snaquebar_id/bites/:bite_type/preview_create(.:format)
 * @param {any} snaquebar_id
 * @param {any} bite_type
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const preview_bite_admin_snaquebar_bites_path = __jsr.r({"snaquebar_id":{"r":true},"bite_type":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"snaquebars"],[2,[7,"/"],[2,[3,"snaquebar_id"],[2,[7,"/"],[2,[6,"bites"],[2,[7,"/"],[2,[3,"bite_type"],[2,[7,"/"],[2,[6,"preview_create"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/bites/preview_create(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const preview_create_admin_bites_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"bites"],[2,[7,"/"],[2,[6,"preview_create"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/bites/:id/preview_update(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const preview_update_admin_bite_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"bites"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"preview_update"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/campaigns/:id/publish(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const publish_admin_campaign_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"campaigns"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"publish"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/snaquebars/:id/publish(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const publish_admin_snaquebar_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"snaquebars"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"publish"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/representations/redirect/:signed_blob_id/:variation_key/*filename(.:format)
 * @param {any} signed_blob_id
 * @param {any} variation_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_blob_representation_path = __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[6,"redirect"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/representations/proxy/:signed_blob_id/:variation_key/*filename(.:format)
 * @param {any} signed_blob_id
 * @param {any} variation_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_blob_representation_proxy_path = __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[6,"proxy"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/:inbound_email_id/incinerate(.:format)
 * @param {any} inbound_email_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_incinerate_path = __jsr.r({"inbound_email_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[3,"inbound_email_id"],[2,[7,"/"],[2,[6,"incinerate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/:inbound_email_id/reroute(.:format)
 * @param {any} inbound_email_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_reroute_path = __jsr.r({"inbound_email_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[3,"inbound_email_id"],[2,[7,"/"],[2,[6,"reroute"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/sources(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_sources_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"sources"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/direct_uploads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_direct_uploads_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"direct_uploads"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_key/*filename(.:format)
 * @param {any} encoded_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_disk_service_path = __jsr.r({"encoded_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mailgun/inbound_emails/mime(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mailgun_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mailgun"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"mime"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mandrill/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mandrill_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mandrill"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mandrill/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mandrill_inbound_health_check_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mandrill"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/postmark/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_postmark_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"postmark"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/relay/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_relay_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"relay"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/sendgrid/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_sendgrid_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"sendgrid"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/blobs/redirect/:signed_id/*filename(.:format)
 * @param {any} signed_id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_service_blob_path = __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[6,"redirect"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/blobs/proxy/:signed_id/*filename(.:format)
 * @param {any} signed_id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_service_blob_proxy_path = __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[6,"proxy"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/campaigns/:campaign_id/snaqueing_action_aggregations/rates(.:format)
 * @param {any} campaign_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rates_admin_campaign_snaqueing_action_aggregations_path = __jsr.r({"campaign_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"campaigns"],[2,[7,"/"],[2,[3,"campaign_id"],[2,[7,"/"],[2,[6,"snaqueing_action_aggregations"],[2,[7,"/"],[2,[6,"rates"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/projects/:project_id/snaqueing_action_aggregations/rates(.:format)
 * @param {any} project_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rates_admin_project_snaqueing_action_aggregations_path = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"snaqueing_action_aggregations"],[2,[7,"/"],[2,[6,"rates"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/snaquebars/:snaquebar_id/snaqueing_action_aggregations/rates(.:format)
 * @param {any} snaquebar_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rates_admin_snaquebar_snaqueing_action_aggregations_path = __jsr.r({"snaquebar_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"snaquebars"],[2,[7,"/"],[2,[3,"snaquebar_id"],[2,[7,"/"],[2,[6,"snaqueing_action_aggregations"],[2,[7,"/"],[2,[6,"rates"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/snaquebars/:id/remove_from_clipboard(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const remove_from_clipboard_admin_snaquebar_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"snaquebars"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"remove_from_clipboard"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const root_path = __jsr.r({}, [7,"/"]);

/**
 * Generates rails route to
 * /api-docs
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rswag_api_path = __jsr.r({}, [2,[7,"/"],[6,"api-docs"]]);

/**
 * Generates rails route to
 * /api-docs
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rswag_ui_path = __jsr.r({}, [2,[7,"/"],[6,"api-docs"]]);

/**
 * Generates rails route to
 * /sessions/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const session_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"sessions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /sessions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sessions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sessions"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /healthchecks/sessions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sessions_healthchecks_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"healthchecks"],[2,[7,"/"],[2,[6,"sessions"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /sessions/sudo(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sessions_sudo_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sessions"],[2,[7,"/"],[2,[6,"sudo"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /shared_campaign_previews/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shared_campaign_preview_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared_campaign_previews"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sign_in_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /snaquebars/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const snaquebar_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"snaquebars"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /snaquebars(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const snaquebars_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"snaquebars"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /recede_historical_location(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const turbo_recede_historical_location_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"recede_historical_location"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /refresh_historical_location(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const turbo_refresh_historical_location_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"refresh_historical_location"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /resume_historical_location(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const turbo_resume_historical_location_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"resume_historical_location"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /two_factor_authentication/challenge(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const two_factor_authentication_challenge_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"two_factor_authentication"],[2,[7,"/"],[2,[6,"challenge"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /two_factor_authentication/totp(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const two_factor_authentication_totp_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"two_factor_authentication"],[2,[7,"/"],[2,[6,"totp"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/campaign_suggestions/:id/update_conversation(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_conversation_admin_campaign_suggestion_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"campaign_suggestions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_conversation"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_token(.:format)
 * @param {any} encoded_token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_rails_disk_service_path = __jsr.r({"encoded_token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /validate_snaqueing(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const validate_snaqueing_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"validate_snaqueing"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /metrics
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const yabeda_prometheus_exporter_path = __jsr.r({}, [2,[7,"/"],[6,"metrics"]]);

